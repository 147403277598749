<template>
  <div class="ym-page">

    <!-- 顶部搜索栏 -->
    <!-- <div class="tableList-form">
      <ymForm ref="tableForm" refCode="tableForm"
        :formValue="formValue"
        :formData="formData"
        :inline="true"
        @onSubmit="onSubmit">
      </ymForm>
      <div class="tableList-form-buttonBox">
        <el-button size="medium" @click="resetForm('formValue')" plain >重置</el-button>
        <el-button size="medium" @click="submitForm('tableForm')" type="primary">查询</el-button>
      </div>
    </div> -->

    <!-- 列表组件 -->
    <div class="table-group">
      <ym-table-list
        :tableOption="{size: 'mini'}"
        :loading="loading"
        :headData="headData"
        :data="tableData"
        :columns="columns"
        fit
        stripe
        row-key="id"
        >
        <!-- 使用列表插槽 -->
        <template #status="{row}">
          {{row.status | cnStatus}}
        </template>
      </ym-table-list>
    </div>

    <!-- 新增表单 -->
    <el-dialog class="tableList-addForm" width="800px" :title="dialogTitle" :visible.sync="dialogShow" :close-on-click-modal="false">
      <ymForm label-position="left"
        ref="addForm"
        refCode="addForm"
        :formValue="dialogValue"
        :formData="dialogForm"
        @onSubmit="onSubmit">
        <template #roleTree>
          <el-tree
            :data="datatree"
            show-checkbox
            node-key="controlID"
            ref="tree"
            :props="defaultProps">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span :title="node.name">{{ data.title }}</span>
            </span>
          </el-tree>
        </template>
      </ymForm>
      <div class="tableList-form-buttonBox">
        <el-button v-show="dialogForm[0]&&dialogForm[0].showbutton" size="medium" @click="submitForm('addForm')" type="primary">提交 </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
import ymForm from '@/components/YmForm'
import tableObj from './data.js'
export default {
  components: {
    YmTableList,
    ymForm
  },
  data() {
    return {
      loading: false,
      tableData: [],
      formValue: {},
      dialogTitle: '',
      dialogShow: false,
      dialogValue: {},
      dialogForm: [],
      tableObj: tableObj,
      columns: tableObj.columnsData,
      addForm: tableObj.addForm,
      formData: tableObj.formData,
      headData: tableObj.headData,

      datatree: [],
      defaultProps: {
        children: 'child',
        label: 'label'
      },
      permissionsList: []
    }
  },
  mounted() {
    this.getData()
    this.listControls()
  },
  methods: {
    // 清空树选项
    getClearNode() {
      if (this.$refs.tree) {
        this.$refs.tree.setCheckedKeys([])
      }
    },
    // 获取权限列表
    async listControls() {
      const { data, code } = await this.$api.rbac.listControls()
      if (code === 200) {
        // 处理树结构
        var tree = []
        for (let node of data) {
          var array = []
          for (let leaf of data) {
            if (node.controlID === leaf.parentID) {
              array.push(leaf)
            }
          }
          // if (array.length !== 0) {
          node.child = array
          if (!node.parentID && node.controlID) {
            tree.push(node)
            // console.log('data:', tree)
            this.datatree = tree
          }
          // }
        }
      }
    },
    // 获取角色权限
    async getPermissionsList(id) {
      let res = await this.$api.rbac.listPermissions({ roleID: id })
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys([])
        if (res.code === 200) {
          res.data.controls.forEach((i, n) => {
            var node = this.$refs.tree.getNode(i)
            // console.log(node.isLeaf)
            if (node.isLeaf) {
              this.$refs.tree.setChecked(node, true)
            }
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取表格数据
    async getData() {
      this.loading = true
      let res = await this.$api.rbac.listRoles()
      this.tableData = res.data
      this.loading = false
    },
    // 重置表单
    resetForm(key) {
      this.$set(this, key, {})
      this.getData()
    },
    // 点击提交按钮,触发提交事件
    submitForm(id) {
      this.$refs[id].onSubmit()
    },
    // 提交事件
    async onSubmit(form, ref) {
      if (!form.roleID) { // 添加
        // 添加角色
        let res = await this.$api.rbac.addRole(form)
        if (res.code === 200) {
          let params = {
            role: res.data,
            controls: this.$refs.tree.getHalfCheckedNodes().concat(this.$refs.tree.getCheckedNodes())
          }
          let res1 = await this.$api.rbac.addPermission(params)
          if (res1.code === 200) {
            this.dialogShow = false
            this.dialogValue = {}
            this.getData()
            this.$message.success('添加角色成功')
          }
        } else {
          this.$message.error(res.message)
        }
      } else { // 编辑
        let params = {
          role: form,
          controls: this.$refs.tree.getHalfCheckedNodes().concat(this.$refs.tree.getCheckedNodes())
        }
        // 修改角色
        await this.$api.rbac.updateRole(form)
        // 角色与权限绑定
        let res = await this.$api.rbac.addPermission(params)
        if (res.code === 200) {
          this.dialogShow = false
          this.dialogValue = {}
          this.getData()
          this.$message.success('更新权限成功')
        } else {
          this.$message.error(res.message)
        }
      }
    },
    // 删除
    deleteRow(row) {
      let thisVue = this
      this.$confirm('此操作将永久删除该条记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let res = await thisVue.$api.rbac.deleteRole(row)
        if (res.code === 200) {
          thisVue.getData()
          thisVue.$message.success('删除成功')
        } else {
          thisVue.$message.error(res.message)
        }
      })
    }
  },
  filters: {
    cnStatus(value) {
      return value === 1 ? '正常' : '拉黑'
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-input{
  width: 84% !important;
}
/deep/.el-tree{
  width: 84% !important;
}
.tableList {
  .table-group {
    /deep/.ym-table .ym-table-header{
      padding-left: 25px;
    }
    /deep/.el-table {
      thead .cell {
        font-size: 14px;
      }
      td:first-child .cell,th:first-child .cell {
        padding-left: 25px;
      }
    }
  }
}
.tableList-addForm{
  /deep/.el-dialog{
    max-width: 600px;
    .el-dialog__body{
      padding: 30px 80px 50px;
      .tableList-form-buttonBox{
        text-align: center;
      }
    }
    .passwordContent{
      margin: 20px 0 50px 0;
    }
    .el-tree {
      border: 1px solid $--border-color-base;
      border-radius: 5px;
      transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    }
    .el-tree-node__content {
      border-radius: 5px;
    }
  }
}
/deep/.tableList-addForm .el-dialog .el-dialog__body .tableList-form-buttonBox {
    text-align: right;
    margin-top: 16px;
}
</style>
