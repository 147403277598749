import { activeArray } from '@/utils/localStorage.js'
// 表单项配置
let formData = [
  {
    items: {
      name: {
        label: '关键字',
        type: 'text',
        clearable: true
      }
    }
  }
]

// 新增表单配置
let addForm = [
  {
    showbutton: true,
    slotArr: ['roleTree'],
    items: {
      roleName: {
        label: '角色名称',
        type: 'text',
        maxLength: 8,
        placeholder: '请输入',
        clearable: true,
        rules: [
          { required: true, message: '请输入角色名称' },
          { min: 1, max: 20, message: '长度在 1 到 8 个字符' },
          {
            validator: function (rule, value, callback) {
              var specialKey = /^[\u4E00-\u9FA5A-Za-z0-9]+$/
              if (value && specialKey.test(value)) {
                return callback()
              } else {
                return callback(new Error('只能输入长度为1~8字符的中文、英文、数字但不包含特殊字符'))
              }
            }
          }
        ]
      },
      // description: {
      //   label: '角色描述',
      //   type: 'text'
      // }
      roleTree: {
        label: '权限配置',
        type: 'slot',
        name: 'roleTree'
      }
    }
  }
]

// 编辑表单配置
let editForm = [
  {
    showbutton: true,
    slotArr: ['roleTree'],
    items: {
      roleName: {
        label: '角色名称',
        type: 'text',
        maxLength: 8,
        placeholder: '请输入',
        clearable: true,
        rules: [
          { required: true, message: '请输入角色名称' },
          { min: 1, max: 8, message: '长度在 1 到 8 个字符' },
          {
            validator: function (rule, value, callback) {
              var specialKey = /^[\u4E00-\u9FA5A-Za-z0-9]+$/
              if (value && specialKey.test(value)) {
                return callback()
              } else {
                return callback(new Error('只能输入长度为1~8字符的中文、英文、数字但不包含特殊字符'))
              }
            }
          }
        ]
      },
      // description: {
      //   label: '角色描述',
      //   type: 'text'
      // },
      roleTree: {
        label: '权限配置',
        type: 'slot',
        name: 'roleTree'
      }
    }
  }
]

// 表头图标配置
let headData = {
  title: '角色管理',
  items: [
    /* 新增按钮 */
    {
      style: 'button',
      label: '新增',
      icon: 'el-icon-plus',
      size: 'medium',
      type: 'primary',
      // 控制按钮显示隐藏
      showFilter: (item, thisVue) => {
        return activeArray('/auth/auth-add-role')
      },
      clickEvent: (thisVue) => {
        thisVue.dialogForm = thisVue.tableObj.addForm
        thisVue.dialogValue = {}
        thisVue.getClearNode()
        // 清除点编辑之后清空表单出现的校验提示
        if (thisVue.$refs.addForm) {
          thisVue.$nextTick(() => {
            thisVue.$refs.addForm.resetFields()
          })
        }
        thisVue.dialogTitle = '新增角色'
        thisVue.$set(thisVue, 'dialogShow', true)
      }
    }
  ]
}

// 列表columns配置
let columnsData = [
  {
    label: '序号',
    type: 'index',
    align: 'center',
    show: true,
    width: 100
  },
  {
    label: '角色名称',
    prop: 'roleName',
    show: true
  },
  {
    label: '状态',
    prop: 'status',
    show: true,
    enableSlot: true
  },
  {
    label: '操作',
    prop: 'operators',
    align: 'right',
    fixed: 'right',
    width: 280,
    children: {
      edit: {
        label: '编辑',
        icon: 'ym-icon-bianji',
        // 控制按钮显示隐藏
        showFilter: (item, row, thisVue) => {
          return activeArray('/auth/auth-update-role')
        },
        // action: activeArray('/auth/auth-update-role'),
        clickEvent: (thisVue, row) => {
          thisVue.dialogForm = thisVue.tableObj.editForm
          thisVue.dialogValue = row
          thisVue.dialogTitle = '编辑 ' + row.roleName
          thisVue.getPermissionsList(row.roleID)
          thisVue.$set(thisVue, 'dialogShow', true)
        }
      },
      delete: {
        label: '删除',
        icon: 'ym-icon-shanchu',
        color: '#FF8851',
        // 控制按钮显示隐藏
        showFilter: (item, row, thisVue) => {
          return activeArray('/auth/auth-delete-role')
        },
        // action: activeArray('/auth/auth-delete-role'),
        clickEvent: (thisVue, row) => {
          thisVue.deleteRow(row)
        }
      }
    }
  }
]

// 分页配置项
// let pageData = {
//   size: 8, // 每页的数据量
//   total: 0, // 数据总量
//   layout: 'prev, pager, next, total, jumper, slot',
//   currentPage: 1 // 当前页
// }

let tableObj = {
  'columnsData': columnsData, // 表格列/操作列配置
  'formData': formData, // 搜索表单项
  'headData': headData, // 表头图标配置
  'addForm': addForm, // 新增表单配置
  'editForm': editForm // 编辑表单配置
  // 'pageData': pageData // 分页数据
}

export default tableObj
